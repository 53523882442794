
import images from "./widget/images"
export default
    {
        "type": "object",
        "properties": {
            "banner": images({}),

            "doForScenic": {
                "type": "string",
                "ui": {
                    "label": "doForScenic"
                }
            },

            "serviceBackground": images({}),
            "vrExperience": images({}),
            "vrText": {
                "type": "string",
                "ui": {
                    "label": "vrText"
                }
            },
            "drawMapTitle": {
                "type": "string",
                "ui": {
                    "label": "drawMapTitle"
                }
            },
            "drawMap": images({}),
            "vrTitle": {
                "type": "string",
                "ui": {
                    "label": "vrTitle"
                }
            },
            "vrSubTitle": {
                "type": "string",
                "ui": {
                    "label": "vrSubTitle"
                }
            },
            "vrImage": images({}),


            "experience": {
                "type": "array",
                "items": {
                    "type": "object",
                    "properties": {
                        "title": {
                            "type": "string",
                            "ui": {
                                "label": "title"

                            },
                            "rules": {
                                "required": true
                            }
                        },
                        "thumb": images({}),

                    },
                    "ui": {
                        "label": "体验"
                    }
                },
                "ui": {
                    "label": "体验"
                }
            }





        }
    }