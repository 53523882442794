

































































































































































// @ts-nocheck
import Vue from "vue";
import formSchemaSource from "@/common/form/scenic";
// @ts-ignore
import { Carousel3d, Slide } from "vue-carousel-3d";
// @ts-ignore
import VueDragResize from "vue-drag-resize";

export default Vue.extend({
  components: {
    Carousel3d,
    Slide,
    VueDragResize,
  },
  data() {
    return {
      id: 1,
      formSchemaSource,
      form: {},
      home: {},
      data: {},
      categorys: [],
      services: [],
      certificates: [],
      partners: [],
    };
  },
  mounted() {
    console.log(!!this.id);
    this.getAllDatas();

    this.$ScrollReveal().reveal(".part3,.part4,.part5", {
      duration: 1500,
      distance: "20%",
      origin: "top",
      scale: 0.9,
    });
  },

  methods: {
    getAllDatas() {
      let data = this.$store.state.allData;
      this.certificates = data["certificates"];
      this.services = data["services"];
      this.data = data["scenic"][0];
      this.home = data["home"][0];
    },
    onResize(index: number, newRect: any) {
      console.log(index, newRect);
      // this.services[index].left = newRect.left;
      // this.services[index].top = newRect.top;
    },
  },
});
